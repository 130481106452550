import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { shape, string } from "prop-types";
import Email from "./items/email";
import Phone from "./items/phone";
import Fax from "./items/fax";
import Linkedin from "./items/linkedin";

const config = require("../../config.json")
const client = config.APP_CLIENT;
const imgName = config.APP_IMG_NAME;
const compagnyName = config.APP_COMPAGNY_NAME;
const colorBackgroundTable = config.APP_COLORBACKGROUNDTABLE;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(35vh);
  @media (max-width: 1280px) {
    transform: translateY(0vh);
  }
`;
const ContainerSocial = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "0px",
  paddingTop: "0px"
};
const SignatureStyle = {
  borderSpacing: "0px",
  backgroundColor: colorBackgroundTable
};
const ImageWrapper = {
  position: "relative",
  width: '208px',
  objectFit: "cover",
  minWidth: "200px",
};
const imgLeft = {
  position: "absolute",
  height: "100%",
  width: '208px',
  top: "0",
};

const Image = {
  width: '208px',
  height: "292px",
  margin: "auto",
  display: "block",
};

const TextWrapper = {
  fontFamily: 'Arial',
  lineHeight: '1.45',
  verticalAlign: 'top',
  textDecoration: 'none',
  paddingLeft: "20px"
};
const Name = {
  margin: '0',
  fontWeight: 'bold',
  fontSize: '16px',
  display: "inline"
};
const Job = {
  margin: '0px 0 10px 0',
  fontSize: '15px',
  color: '#e26d5a',
  fontWeight: '700'
};
const Pole = {
  margin: "0px 0px 5px",
  fontSize: "15px",
  lineHeight: "1.15",
  color: "#e26d5a",
  fontWeight: "bold",
};
const Link = {
  color: '#fff',
  textDecoration: 'none',
  fontWeight: '700',
  padding: '4px 7px',
  backgroundColor: '#e26d5a'
};
const StandardURL = {
  margin: "10px 0px 10px 0px",
  fontSize: "15px",
};
const Slogan = {
  margin: "10px 0 10px 0",
  fontSize: "13px",
  lineHeight: "1.38",
  color: "#4b5264",
  whiteSpace: "pre-line",
};
const name = {
  display: "flex",
  flexDirection: "row",
}

function constructImgUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${imgName}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, file, typeImage }) => {
  let urlUploadsImg = "";
  if (user.urlImage === "" || user.urlImage === null) {
    if (typeImage === "static") {
      urlUploadsImg = constructImgUrl(client, 'jpg');
    } else if (typeImage === "gif") {
      urlUploadsImg = constructImgUrl(client, 'gif');
    }
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {compagnyName} | Signature
        </title>
      </Helmet>
      <div className='wrapper-table'>
        <table className="signature" style={SignatureStyle}>
          <tbody>
            <tr>
              <td className="image__wrapper" style={ImageWrapper}>
                  <a style={imgLeft} href={`https://${user.urlImage}`} target='_blank' rel="noreferrer">
                    <img src={urlUploadsImg} style={Image} width="208" height="292" alt="logo" />
                  </a>
              </td>
              <td className="text__wrapper" style={TextWrapper}>
                <div style={name}>
                  <p style={Object.assign({}, Name, { color: "#4b5264" })}>
                    {user.firstname}
                    &nbsp;
                  </p>
                  <p style={Object.assign({}, Name, { color: "#4b5264" })}>
                    {user.lastname}
                  </p>
                </div>
                <h4 style={Job}>{user.job}</h4>
                {user.availability ? (
                  <p style={Pole}>{user.availability}</p>
                  ) : null}
                  {user.email ? (
                    <Email email={user.email} />
                  ) : null}
                  {user.phone ? (
                    <Phone phone={user.phone} />
                  ) : null}
                  <p style={Slogan}> Zone Industrielle - B. P. 6 <br/> 67340 INGWILLER - FRANCE</p>
                {user.fax ? (
                  <Fax fax={user.fax} />
                ) : null}
                {user.slogan ? (
                  <p style={Slogan}
                    dangerouslySetInnerHTML={{ __html: user.slogan }}>
                  </p>
                ) : null}
                <p style={StandardURL}>
                  <a target={"_blank"} href={user.website} style={Link}>keck-chimie.fr/en/</a>
                </p>
                <p style={ContainerSocial}>
                  <Linkedin linkedin={user.linkedin} />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

SignatureFormatter.propTypes = {
  user: shape({
    name: string.isRequired,
    job: string.isRequired,
    availability: string,
    phone: string
  }).isRequired
};

export default SignatureFormatter;
