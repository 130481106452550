import React from "react";
const config = require("../../../config.json");

const Mail = {
  margin: "10px 0px 10px 0px",
  fontSize: "15px",
  lineHeight: "1.38",
  textDecoration: "none",
  paddingTop: "3px",
};
const MailLink = {
  textDecoration: "none",
  color: "#4b5264",
  fontSize: "15px",
  margin: "10px 0px 0px 0px",
};

export default function Email(email) {
  return (
    <p style={Mail}>
      {/* <img src={`https://files.myclientisrich.com/${client}/mail.png`} style={iconLogo} alt="logo" width="20" height="15"></img> */}
      <a href={`mailto:${email.email}`} style={MailLink}>
        {email.email}
      </a>
    </p>
  );
}
