import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const iconLogo = {
  marginRight: "10px",
};
const PhoneLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  paddingRigth: "5px",
  marginRigth: "5px",
};
const PhoneStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "15px",
  color: compagnyColorPrimary,
  margin: "0px 0px 3px",
  padding: "2px 0",
};

function telWithoutSpaces(number) {
  return number.replace(/\s/g, "");
}

export default function Fax(fax) {
  return (
    <p style={PhoneStyle}>
      {/* <img src={`https://files.myclientisrich.com/${client}/phone.png`} style={iconLogo} alt="logo" width="19" height="20"></img> */}
      <a href={`tel:${telWithoutSpaces(fax.fax)}`}
        style={PhoneLink}
      >
        {fax.fax}
      </a>
      <br />
    </p>
  );
}
