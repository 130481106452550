import React, { useState } from "react";
import SignatureFormatter from "./components/SignatureFormatter";
import styled from "@emotion/styled";
import "./style.css";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 100%;
  display: block;
`;
const InputWrapper = styled.div`
  margin: 0 0;
  padding-top: 20px;
`;
const leftPart = {
  width: "100%"
}
const inputWrapper = {
  display: "flex",
  flexDirection: "row",
  gap: "15px"
}

const userData = {
  firstname: "Prénom",
  lastname: "Nom",
  urlImage: "",
  job: "Titre du poste",
  phone: "+33 (0)3 XX XX XX XX",
  postalAdress: `Zone Industrielle - B. P. 6
  67340 INGWILLER - FRANCE`,
  email: "Adresse email",
  website: "https://www.keck-chimie.fr/en/",
  linkedin: "https://www.linkedin.com/company/keck-chimie-sa/"
};

export default function App() {
  const [user, setUser] = useState(userData);
  const [isChecked, setIsChecked] = useState(false);
  const [typeImage, setTypeImage] = useState('static');
  const [gifChecked, setGifChecked] = useState(false);
  const [staticChecked, setStaticChecked] = useState(false);

  const imgChecked = [ gifChecked, staticChecked]

  const handleGifChange = (event) => {
    setGifChecked(event.target.checked);
    setStaticChecked(false);
    setTypeImage(event.target.value);
  };

  const handleStaticChange = (event) => {
    setGifChecked(false);
    setStaticChecked(event.target.checked);
    setTypeImage(event.target.value);
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    setIsChecked(!isChecked);
  };

  const userInfoChange = e => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  function copyToClipboard() {
    let tableToCopy = document.querySelector('.wrapper-table')
    let range = document.createRange();
    range.selectNode(tableToCopy);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }

  function responseCopy() {
    let button = document.querySelector('button');
      button.classList.add('copied');
    setTimeout(() => {
      button.classList.remove('copied');
    }, 2000);
  }

  return (
    <Wrapper>
      <Container>
        <section className="formulaire">
          <div style={leftPart}>
            <SignatureFormatter user={user} message={isChecked} typeImage={typeImage} />
          </div>
          <div className="rightPart">
            <h1 className="title">Signature Generator</h1>
            <h2 className="title">By my client is rich</h2>
            <p className="inputLabel">Noms</p>
            {/* ***************          NOM ET PRENOM        *************** */}
            <InputWrapper style={inputWrapper}>
              <input
                name="lastname"
                onChange={userInfoChange}
                value={user.lastname}
                placeholder="Nom"
                onBlur={() => {
                  user.lastname === "" ? setUser({ ...user, lastname: userData.lastname }) : setUser({ ...user, lastname: user.lastname });
                }}
                onFocus={() => {
                  user.lastname === userData.lastname ? setUser({ ...user, lastname: "" }) : setUser({ ...user, lastname: user.lastname });
                }}
              />
              <input
                name="firstname"
                onChange={userInfoChange}
                value={user.firstname}
                placeholder="Prénom"
                onBlur={() => {
                  user.firstname === "" ? setUser({ ...user, firstname: userData.firstname }) : setUser({ ...user, firstname: user.firstname });
                }}
                onFocus={() => {
                  user.firstname === userData.firstname ? setUser({ ...user, firstname: "" }) : setUser({ ...user, firstname: user.firstname });
                }}
              />
            </InputWrapper>
            {/* ***************          TITRE DU POSTE        *************** */}
            <InputWrapper>
              <input
                name="job"
                onChange={userInfoChange}
                value={user.job}
                placeholder="Titre du poste"
                onBlur={() => {
                  user.job === "" ? setUser({ ...user, job: userData.job }) : setUser({ ...user, job: user.job });
                }}
                onFocus={() => {
                  user.job === userData.job ? setUser({ ...user, job: "" }) : setUser({ ...user, job: user.job });
                }}
              />
            </InputWrapper>
            <p className="inputLabel">Coordonnées</p>
            {/* ***************          ADRESSE EMAIL        *************** */}
            <InputWrapper>
              <input
                name="email"
                onChange={userInfoChange}
                value={user.email}
                placeholder="Adresse email"
                onBlur={() => {
                  user.email === "" ? setUser({ ...user, email: userData.email }) : setUser({ ...user, email: user.email });
                }}
                onFocus={() => {
                  user.email === userData.email ? setUser({ ...user, email: "" }) : setUser({ ...user, email: user.email });
                }}
              />
            </InputWrapper>
            {/* ***************          TELEPHONE        *************** */}
            <InputWrapper>
              <input
                name="phone"
                onChange={userInfoChange}
                value={user.phone}
                placeholder="Téléphone"
                onBlur={() => {
                  user.phone === "" ? setUser({ ...user, phone: userData.phone }) : setUser({ ...user, phone: user.phone });
                }}
                onFocus={() => {
                  user.phone === userData.phone ? setUser({ ...user, phone: "" }) : setUser({ ...user, phone: user.phone });
                }}
              />
            </InputWrapper>
            {/* ***************          ADRESSE POSTALE        *************** */}
            {/* <InputWrapper>
              <textarea
                name="postalAdress"
                onChange={userInfoChange}
                value={user.postalAdress}
                type="textarea"
                placeholder="Adresse postal"
                onBlur={() => {
                  user.postalAdress === "" ? setUser({ ...user, postalAdress: userData.postalAdress }) : setUser({ ...user, postalAdress: user.postalAdress });
                }}
                onFocus={() => {
                  user.postalAdress === userData.postalAdress ? setUser({ ...user, postalAdress: "" }) : setUser({ ...user, postalAdress: user.postalAdress });
                }}
              />
            </InputWrapper> */}
            {/* ***************          SITE INTERNET        *************** */}
            {/* <p className="inputLabel">Site internet</p>
            <InputWrapper>
              <input
                name="website"
                onChange={userInfoChange}
                value={user.website}
                placeholder="Site internet"
                onBlur={() => {
                  user.website === "" ? setUser({ ...user, website: userData.website }) : setUser({ ...user, website: user.website });
                }}
                onFocus={() => {
                  user.website === userData.website ? setUser({ ...user, website: "" }) : setUser({ ...user, website: user.website });
                }}
              />
            </InputWrapper> */}
            {/* ***************          RESEAUX SOCIAUX        *************** */}
            {/* <p className="inputLabel">Social</p> */}
            {/* ***************          LINKEDIN        *************** */}
            {/* <InputWrapper>
              <input
                name="linkedin"
                onChange={userInfoChange}
                value={user.linkedin}
                placeholder="Linkedin"
                onBlur={() => {
                  user.linkedin === "" ? setUser({ ...user, linkedin: userData.linkedin }) : setUser({ ...user, linkedin: user.linkedin });
                }}
                onFocus={() => {
                  user.linkedin === userData.linkedin ? setUser({ ...user, linkedin: "" }) : setUser({ ...user, linkedin: user.linkedin });
                }}
              />
            </InputWrapper> */}
            {/* ***************          IMAGE        *************** */}
            {/* <p className="inputLabel">Image</p> */}
            {/* ***************          IMAGE STATIQUE OU GIF        *************** */}
            {/* <div className="wrapperTypeImage">
              <div className="checkItem gif">
                <input
                  type="checkbox"
                  checked={gifChecked}
                  onChange={handleGifChange}
                  value="gif"
                />
              </div>
              <div className="checkItem static">
                <input
                  type="checkbox"
                  checked={staticChecked}
                  onChange={handleStaticChange}
                  value="static"
                />
              </div>
            </div> */}
            <button
              className="copyButton"
              onClick={() => {
                copyToClipboard();
                responseCopy();
              }}
            ><p className="btnCopy"></p></button>
          </div>
        </section>
      </Container>
    </Wrapper>
  );
}
